define("discourse/plugins/discourse-news/discourse/news-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: 'discovery',
    map() {
      this.route('news', {
        path: '/news',
        resetNamespace: true
      });
    }
  };
});