define("discourse/plugins/discourse-news/discourse/raw-templates/list/news-item", ["exports", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "1": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "    " + container.escapeExpression((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "list.news-item-title", {
        "name": "raw",
        "hash": {
          "topic": "topic"
        },
        "hashTypes": {
          "topic": "PathExpression"
        },
        "hashContexts": {
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 4,
            "column": 4
          },
          "end": {
            "line": 4,
            "column": 46
          }
        }
      })) + "\n";
    },
    "3": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "    <div class=\"news-item-thumbnail\">\n      <a href='" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.url", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 9,
            "column": 15
          },
          "end": {
            "line": 9,
            "column": 28
          }
        }
      })) + "'>\n        <img src=" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.image_url", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 10,
            "column": 17
          },
          "end": {
            "line": 10,
            "column": 36
          }
        }
      })) + " loading=\"lazy\">\n      </a>\n    </div>\n";
    },
    "5": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "      " + ((stack1 = lookupProperty(helpers, "get").call(depth0 != null ? depth0 : container.nullContext || {}, "topic.description", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 21,
            "column": 6
          },
          "end": {
            "line": 21,
            "column": 29
          }
        }
      })) != null ? stack1 : "") + "\n";
    },
    "7": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "      " + ((stack1 = lookupProperty(helpers, "get").call(depth0 != null ? depth0 : container.nullContext || {}, "topic.escapedNewsBody", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 23,
            "column": 6
          },
          "end": {
            "line": 23,
            "column": 33
          }
        }
      })) != null ? stack1 : "") + "\n";
    },
    "9": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        alias3 = container.hooks.helperMissing,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "      <div class='news-item-replies posts-map " + alias2(lookupProperty(helpers, "get").call(alias1, "likesHeat", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 29,
            "column": 46
          },
          "end": {
            "line": 29,
            "column": 59
          }
        }
      })) + "' title='" + alias2(lookupProperty(helpers, "get").call(alias1, "title", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 29,
            "column": 68
          },
          "end": {
            "line": 29,
            "column": 77
          }
        }
      })) + "'>\n        <a href class='posts-map badge-posts " + alias2(lookupProperty(helpers, "get").call(alias1, "likesHeat", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 30,
            "column": 45
          },
          "end": {
            "line": 30,
            "column": 58
          }
        }
      })) + "'>" + alias2((lookupProperty(helpers, "number") || depth0 && lookupProperty(depth0, "number") || alias3).call(alias1, "topic.replyCount", {
        "name": "number",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 30,
            "column": 60
          },
          "end": {
            "line": 30,
            "column": 87
          }
        }
      })) + "</a>\n        <span>" + alias2((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias3).call(alias1, "replies_lowercase", {
        "name": "i18n",
        "hash": {
          "count": "topic.replyCount"
        },
        "hashTypes": {
          "count": "PathExpression"
        },
        "hashContexts": {
          "count": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 31,
            "column": 14
          },
          "end": {
            "line": 31,
            "column": 65
          }
        }
      })) + "</span>\n      </div>\n";
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "<td class='main-link news-item clearfix' colspan=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "titleColSpan", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 50
          },
          "end": {
            "line": 1,
            "column": 66
          }
        }
      })) + "\">\n  \n" + ((stack1 = lookupProperty(helpers, "unless").call(alias1, "siteSettings.discourse_news_title_below_image", {
        "name": "unless",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 2
          },
          "end": {
            "line": 5,
            "column": 13
          }
        }
      })) != null ? stack1 : "") + "  \n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "topic.image_url", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(3, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 7,
            "column": 2
          },
          "end": {
            "line": 13,
            "column": 9
          }
        }
      })) != null ? stack1 : "") + "  \n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "siteSettings.discourse_news_title_below_image", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 15,
            "column": 2
          },
          "end": {
            "line": 17,
            "column": 9
          }
        }
      })) != null ? stack1 : "") + "\n  <div class=\"news-item-body\">\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "topic.rss", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(5, data, 0),
        "inverse": container.program(7, data, 0),
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 20,
            "column": 4
          },
          "end": {
            "line": 24,
            "column": 11
          }
        }
      })) != null ? stack1 : "") + "  </div>\n\n  <div class=\"news-item-gutter\">\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "showReplies", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(9, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 28,
            "column": 4
          },
          "end": {
            "line": 33,
            "column": 11
          }
        }
      })) != null ? stack1 : "") + "\n    <button class=\"share btn-flat no-text btn-icon\">\n      " + alias2((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || container.hooks.helperMissing).call(alias1, "link", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 36,
            "column": 6
          },
          "end": {
            "line": 36,
            "column": 23
          }
        }
      })) + "\n    </button>\n  </div>\n</td>\n";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/list/news-item", template, {
    core: true
  });
  var _default = _exports.default = template;
});