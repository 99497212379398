define("discourse/plugins/discourse-news/discourse/routes/news", ["exports", "discourse/routes/build-topic-route", "discourse/lib/ajax-error", "discourse/lib/ajax", "@ember/object"], function (_exports, _buildTopicRoute, _ajaxError, _ajax, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _buildTopicRoute.default)('news', {
    model(data, transition) {
      if (this.siteSettings.discourse_news_source === 'rss') {
        return (0, _ajax.ajax)("/news").then(result => {
          return _object.default.create({
            filter: '',
            topics: result.map(t => {
              return _object.default.create({
                title: t.title,
                description: t.description,
                url: t.url,
                image_url: t.image_url,
                rss: true
              });
            })
          });
        }).catch(_ajaxError.popupAjaxError);
      } else {
        return this._super(data, transition);
      }
    },
    afterModel(model) {
      if (this.siteSettings.discourse_news_sidebar_topic_list && !this.site.mobileView) {
        const filter = this.siteSettings.discourse_news_sidebar_topic_list_filter || 'latest';
        return this.store.findFiltered("topicList", {
          filter
        }).then(list => {
          const limit = this.siteSettings.discourse_news_sidebar_topic_list_limit || 10;
          this.set('sidebarTopics', list.topics.slice(0, limit));
        });
      } else {
        return true;
      }
    },
    renderTemplate() {
      this.render("news", {
        controller: "discovery/topics",
        outlet: "list-container"
      });
    },
    setupController(controller, model) {
      this._super(controller, model);
      let extraOpts = {};
      if (this.sidebarTopics) extraOpts['sidebarTopics'] = this.sidebarTopics;
      this.controllerFor("discovery/topics").setProperties(extraOpts);
    }
  });
});