define("discourse/plugins/discourse-news/discourse/initializers/news-initializer", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "@ember/object/computed", "discourse-common/lib/raw-templates", "discourse/lib/intercept-click", "discourse/lib/text", "virtual-dom", "@ember/service", "@ember/runloop", "discourse/components/modal/share-topic", "@ember/application", "@ember/template"], function (_exports, _pluginApi, _decorators, _computed, _rawTemplates, _interceptClick, _text, _virtualDom, _service, _runloop, _shareTopic, _application, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'news-edits',
    initialize(container) {
      const siteSettings = container.lookup('site-settings:main');
      if (!siteSettings.discourse_news_enabled) return;
      (0, _pluginApi.withPluginApi)('0.8.12', api => {
        api.modifyClass('controller:discovery', dt7948.p({
          router: (0, _service.inject)(),
          toggleClass() {
            const route = this.get('router.currentRouteName');
            (0, _runloop.scheduleOnce)('afterRender', () => {
              $('#list-area').toggleClass('news', route === 'news');
            });
          }
        }, [["method", "toggleClass", [(0, _decorators.observes)('router.currentRouteName'), (0, _decorators.on)('init')]]]));
        api.modifyClass('controller:discovery/topics', {
          actions: {
            refresh() {
              const route = this.get('router.currentRouteName');
              if (route === 'news') return;
              return this._super();
            }
          }
        });
        api.modifyClass('component:topic-list', dt7948.p({
          router: (0, _service.inject)(),
          currentRoute: (0, _computed.alias)('router.currentRouteName'),
          newsRoute(currentRoute) {
            return currentRoute === 'news';
          },
          setupNews() {
            const newsRoute = this.get('newsRoute');
            if (newsRoute) {
              const newsCategoryId = this.siteSettings.discourse_news_category;
              const newsCategory = this.site.get("categoriesList").find(c => c.id === newsCategoryId);
              this.set('category', newsCategory);
              $('body').addClass('news');
            } else {
              $('body').removeClass('news');
            }
          }
        }, [["method", "newsRoute", [(0, _decorators.default)('currentRoute')]], ["method", "setupNews", [(0, _decorators.observes)('newsRoute'), (0, _decorators.on)('didInsertElement')]]]));
        api.modifyClass('component:topic-list-item', dt7948.p({
          newsRoute: (0, _computed.alias)('parentView.newsRoute'),
          renderTopicListItem() {
            if (this.get('newsRoute')) {
              const template = (0, _rawTemplates.findRawTemplate)("list/news-item");
              if (template) {
                this.set("topicListItemContents", (0, _template.htmlSafe)(template(this)));
              }
            } else {
              return this._super();
            }
          },
          showReplies(newsRoute) {
            const siteSettings = this.siteSettings;
            const topicSource = siteSettings.discourse_news_source === 'category';
            const showReplies = siteSettings.discourse_news_show_reply_count;
            return newsRoute && topicSource && showReplies;
          },
          click(e) {
            let t = e.target;
            if (!t) {
              return this._super(e);
            }
            if (t.closest(".share")) {
              (0, _application.getOwner)(this).lookup("service:modal").show(_shareTopic.default, {
                model: {
                  category: this.topic.category,
                  topic: this.topic
                }
              });
              return true;
            }
            return this._super(e);
          }
        }, [["method", "renderTopicListItem", [(0, _decorators.observes)("topic.pinned")]], ["method", "showReplies", [(0, _decorators.default)('newsRoute')]]]));
        api.modifyClass('component:site-header', dt7948.p({
          router: (0, _service.inject)(),
          currentRoute: (0, _computed.alias)('router.currentRouteName'),
          rerenderWhenRouteChanges() {
            this.queueRerender();
          },
          buildArgs() {
            return $.extend(this._super(), {
              currentRoute: this.get('currentRoute')
            });
          }
        }, [["method", "rerenderWhenRouteChanges", [(0, _decorators.observes)('currentRoute')]]]));
        api.reopenWidget('header-buttons', {
          html(attrs) {
            let buttons = this._super(attrs) || [];
            let className = 'header-nav-link news';
            if (attrs.currentRoute === 'news') {
              className += ' active';
            }
            let linkAttrs = {
              href: '/news',
              label: 'filters.news.title',
              className
            };
            const icon = siteSettings.discourse_news_icon;
            if (icon && icon.indexOf('/') > -1) {
              linkAttrs['contents'] = () => {
                return [(0, _virtualDom.h)('img', {
                  attributes: {
                    src: icon
                  }
                }), (0, _virtualDom.h)('span', I18n.t('filters.news.title'))];
              };
            } else if (icon) {
              linkAttrs['icon'] = icon;
            }
            buttons.unshift(this.attach('link', linkAttrs));
            return buttons;
          }
        });
        api.modifyClass('model:topic', dt7948.p({
          escapedNewsBody(newsBody) {
            return (0, _text.emojiUnescape)(newsBody);
          },
          basicCategoryLinkHtml(category) {
            return `<a class="basic-category-link"
                     href="${category.url}"
                     title="${category.name}">
                    ${category.name}
                  </a>`;
          }
        }, [["method", "escapedNewsBody", [(0, _decorators.default)("news_body")]], ["method", "basicCategoryLinkHtml", [(0, _decorators.default)("category")]]]));
        api.modifyClass('controller:discovery/topics', dt7948.p({
          showSidebar(showSidebarTopics) {
            return showSidebarTopics && !this.site.mobileView;
          },
          showSidebarTopics(sidebarTopics) {
            return sidebarTopics && siteSettings.discourse_news_sidebar_topic_list;
          }
        }, [["method", "showSidebar", [(0, _decorators.default)('showSidebarTopics')]], ["method", "showSidebarTopics", [(0, _decorators.default)('sidebarTopics')]]]));
      });
    }
  };
});